* {margin: 0; padding: 0} /* reset */
html, body, div#root {
    height: 100%;            
}
body {
    box-sizing: border-box; /* paulirish.com/2012/box-sizing-border-box-ftw/ */
}

@import '../node_modules/milligram/dist/milligram.min.css'; /*If we import milligram we get a standard base of css*/

@import url('./fonts.css');
/* https://fonts.googleapis.com/css?family=Playfair+Display|Julius+Sans+One|Oswald|Roboto */

.mainTitle {
    font-family: 'Playfair Display', serif;
    letter-spacing: 2px;
    line-height: 1;
    text-transform: uppercase;
    font-size: 1.5em;
    font-weight: 700;
    color: black;
  }
  .secondaryTitle {
    font-family: 'Julius Sans One', sans-serif;
    letter-spacing: 5px;
    line-height: 1.4;
    color: white;
    font-size: 1em;
    padding-left: .2em;
  }

  .titleFont {
    font-family: 'Julius Sans One', sans-serif;
  }


  header.cleverorc {
    position: fixed;
    top:0;
    left:0;
    right:0;
    z-index: 10000;
    text-align: center;
    padding: 0.5em;
    text-shadow: 3px 3px 3px rgba(0,0,0, 0.2);
  
    background: linear-gradient(0.25turn,  #68b04d 0%, #1c8a43 80%,rgb(16, 107, 19) 100%);
  }

 main.cleverorc {
     position: relative;
 }

 section.mainContentContainer {
     margin-top: 80px;
 }

  footer.cleverorc {
    /* position: fixed;
    bottom: 0;
    left: 0;
    right: 0; */
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    color: black;
    background: linear-gradient(0.25turn,  #68b04d 0%, #1c8a43 80%,rgb(16, 107, 19) 100%);
  }

  footer.cleverorc .footerContent {
      text-align: center;
      font-size: 0.8em;
  }

  footer.cleverorc .footerContent a {
    color: black;
    text-decoration: underline;
}

  @media screen and (max-width:640px) {
    header.cleverorc {
        text-align: center;
      }

      footer.cleverorc .footerContent {
        font-size: 0.7em;
    }
  }

.mainContent {
    text-align: center;
}

h4 {
    margin-bottom: 0;
    font-weight: bold;
}

.cleverorcrow {
    position: relative;
}

.cleverorcrow ul {
    text-align: left;
    margin-bottom: 0;
    margin-top: 0;
}

.cleverorctext li {
    font-family: 'Oswald', sans-serif;
    margin: 0;
}

.cleverorccolumn {
    border: 1px solid tranparent;
    display:inline-block;
    padding: 20px;
    vertical-align: middle;
}

.joinrow {
    background-color: #f1f2f2;
    padding-top: 1em;
    background: linear-gradient(0.25turn,  #f1f2f2 0%, #aaaaaa 80%,#777777 100%);
}

.orcKingImg {
    width: 17%;
    padding: 0;
    margin-left: -50px;
}

.orcKingImg, .cleverorcfloat {
    float:right;
    width: 300px;
 }

 /* https://stackoverflow.com/questions/27934548/load-a-low-res-background-image-first-then-a-high-res-one/31370466 */
 .phoenixImg {
    padding: 0;
    width: 400px;
    }

.aboutSection, .creditSection {
    width: 75%;
}

.creditSection {
    padding: 2em 0;
    font-size: 0.9em;
}

.creditSection .artists {
    text-align: left;
    width: 80%;
    margin: 0 auto;
}

.creditSection .artist {
    font-weight: bold;
}

.toolList {
    width: 800px;
}

.toolList ul {
    list-style-type: none;
}

.toolList ul ul {
    list-style-type: disc;
}

.toolList .toolListHeader {
       font-family: 'Julius Sans One', sans-serif;
       font-size: 1.2em;
}

@media screen and (max-width:640px) {
    .orcKingImg, .cleverorcfloat {
       float:right;
       width: 60%;
    }

    .mainContent section:first-child {
        padding-top: 1em;
    }

    .cleverorcfirstrow {
        text-align: left;
    }

    .cleverorcfirstrow p {
        margin: 0;
    }

    .toolList {
        width: 100%;
        text-align: left;
    }

    .phoenixImg {
        padding: 0;
        width: 100%;
    }
    .creditSection {
        width: 80%;
    }
    .creditSection .artists {
        text-align: left;
        width: 100%;
        margin: 0 auto;
    }
  }



.cleverorcimage img  {
    width: 100%;
    height: auto;
    -moz-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    filter: FlipH;
    -ms-filter: "FlipH";

}

.icon16 {
    width: 16px;
}