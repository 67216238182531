body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

/* latin-ext */
@font-face {
  font-family: 'Julius Sans One';
  font-style: normal;
  font-weight: 400;
  src: local('Julius Sans One'), local('JuliusSansOne-Regular'), url(https://fonts.gstatic.com/s/juliussansone/v6/1Pt2g8TAX_SGgBGUi0tGOYEga5WOzHsX2aE.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Julius Sans One';
  font-style: normal;
  font-weight: 400;
  src: local('Julius Sans One'), local('JuliusSansOne-Regular'), url(https://fonts.gstatic.com/s/juliussansone/v6/1Pt2g8TAX_SGgBGUi0tGOYEga5WOwnsX.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 400;
  src: local('Oswald Regular'), local('Oswald-Regular'), url(https://fonts.gstatic.com/s/oswald/v16/TK3iWkUHHAIjg752HT8Ghe4.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 400;
  src: local('Oswald Regular'), local('Oswald-Regular'), url(https://fonts.gstatic.com/s/oswald/v16/TK3iWkUHHAIjg752Fj8Ghe4.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 400;
  src: local('Oswald Regular'), local('Oswald-Regular'), url(https://fonts.gstatic.com/s/oswald/v16/TK3iWkUHHAIjg752Fz8Ghe4.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 400;
  src: local('Oswald Regular'), local('Oswald-Regular'), url(https://fonts.gstatic.com/s/oswald/v16/TK3iWkUHHAIjg752GT8G.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic */
@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 400;
  src: local('Playfair Display Regular'), local('PlayfairDisplay-Regular'), url(https://fonts.gstatic.com/s/playfairdisplay/v13/nuFiD-vYSZviVYUb_rj3ij__anPXDTjYgFE_.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 400;
  src: local('Playfair Display Regular'), local('PlayfairDisplay-Regular'), url(https://fonts.gstatic.com/s/playfairdisplay/v13/nuFiD-vYSZviVYUb_rj3ij__anPXDTPYgFE_.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 400;
  src: local('Playfair Display Regular'), local('PlayfairDisplay-Regular'), url(https://fonts.gstatic.com/s/playfairdisplay/v13/nuFiD-vYSZviVYUb_rj3ij__anPXDTLYgFE_.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 400;
  src: local('Playfair Display Regular'), local('PlayfairDisplay-Regular'), url(https://fonts.gstatic.com/s/playfairdisplay/v13/nuFiD-vYSZviVYUb_rj3ij__anPXDTzYgA.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto'), local('Roboto-Regular'), url(https://fonts.gstatic.com/s/roboto/v18/KFOmCnqEu92Fr1Mu72xKOzY.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto'), local('Roboto-Regular'), url(https://fonts.gstatic.com/s/roboto/v18/KFOmCnqEu92Fr1Mu5mxKOzY.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto'), local('Roboto-Regular'), url(https://fonts.gstatic.com/s/roboto/v18/KFOmCnqEu92Fr1Mu7mxKOzY.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto'), local('Roboto-Regular'), url(https://fonts.gstatic.com/s/roboto/v18/KFOmCnqEu92Fr1Mu4WxKOzY.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto'), local('Roboto-Regular'), url(https://fonts.gstatic.com/s/roboto/v18/KFOmCnqEu92Fr1Mu7WxKOzY.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto'), local('Roboto-Regular'), url(https://fonts.gstatic.com/s/roboto/v18/KFOmCnqEu92Fr1Mu7GxKOzY.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto'), local('Roboto-Regular'), url(https://fonts.gstatic.com/s/roboto/v18/KFOmCnqEu92Fr1Mu4mxK.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

* {margin: 0; padding: 0} /* reset */
html, body, div#root {
    height: 100%;            
}
body {
    box-sizing: border-box; /* paulirish.com/2012/box-sizing-border-box-ftw/ */
} /*If we import milligram we get a standard base of css*/
/* https://fonts.googleapis.com/css?family=Playfair+Display|Julius+Sans+One|Oswald|Roboto */

.mainTitle {
    font-family: 'Playfair Display', serif;
    letter-spacing: 2px;
    line-height: 1;
    text-transform: uppercase;
    font-size: 1.5em;
    font-weight: 700;
    color: black;
  }
  .secondaryTitle {
    font-family: 'Julius Sans One', sans-serif;
    letter-spacing: 5px;
    line-height: 1.4;
    color: white;
    font-size: 1em;
    padding-left: .2em;
  }

  .titleFont {
    font-family: 'Julius Sans One', sans-serif;
  }


  header.cleverorc {
    position: fixed;
    top:0;
    left:0;
    right:0;
    z-index: 10000;
    text-align: center;
    padding: 0.5em;
    text-shadow: 3px 3px 3px rgba(0,0,0, 0.2);
  
    background: -webkit-linear-gradient(left,  #68b04d 0%, #1c8a43 80%,rgb(16, 107, 19) 100%);
  
    background: linear-gradient(0.25turn,  #68b04d 0%, #1c8a43 80%,rgb(16, 107, 19) 100%);
  }

 main.cleverorc {
     position: relative;
 }

 section.mainContentContainer {
     margin-top: 80px;
 }

  footer.cleverorc {
    /* position: fixed;
    bottom: 0;
    left: 0;
    right: 0; */
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    color: black;
    background: -webkit-linear-gradient(left,  #68b04d 0%, #1c8a43 80%,rgb(16, 107, 19) 100%);
    background: linear-gradient(0.25turn,  #68b04d 0%, #1c8a43 80%,rgb(16, 107, 19) 100%);
  }

  footer.cleverorc .footerContent {
      text-align: center;
      font-size: 0.8em;
  }

  footer.cleverorc .footerContent a {
    color: black;
    text-decoration: underline;
}

  @media screen and (max-width:640px) {
    header.cleverorc {
        text-align: center;
      }

      footer.cleverorc .footerContent {
        font-size: 0.7em;
    }
  }

.mainContent {
    text-align: center;
}

h4 {
    margin-bottom: 0;
    font-weight: bold;
}

.cleverorcrow {
    position: relative;
}

.cleverorcrow ul {
    text-align: left;
    margin-bottom: 0;
    margin-top: 0;
}

.cleverorctext li {
    font-family: 'Oswald', sans-serif;
    margin: 0;
}

.cleverorccolumn {
    border: 1px solid tranparent;
    display:inline-block;
    padding: 20px;
    vertical-align: middle;
}

.joinrow {
    background-color: #f1f2f2;
    padding-top: 1em;
    background: -webkit-linear-gradient(left,  #f1f2f2 0%, #aaaaaa 80%,#777777 100%);
    background: linear-gradient(0.25turn,  #f1f2f2 0%, #aaaaaa 80%,#777777 100%);
}

.orcKingImg {
    width: 17%;
    padding: 0;
    margin-left: -50px;
}

.orcKingImg, .cleverorcfloat {
    float:right;
    width: 300px;
 }

 /* https://stackoverflow.com/questions/27934548/load-a-low-res-background-image-first-then-a-high-res-one/31370466 */
 .phoenixImg {
    padding: 0;
    width: 400px;
    }

.aboutSection, .creditSection {
    width: 75%;
}

.creditSection {
    padding: 2em 0;
    font-size: 0.9em;
}

.creditSection .artists {
    text-align: left;
    width: 80%;
    margin: 0 auto;
}

.creditSection .artist {
    font-weight: bold;
}

.toolList {
    width: 800px;
}

.toolList ul {
    list-style-type: none;
}

.toolList ul ul {
    list-style-type: disc;
}

.toolList .toolListHeader {
       font-family: 'Julius Sans One', sans-serif;
       font-size: 1.2em;
}

@media screen and (max-width:640px) {
    .orcKingImg, .cleverorcfloat {
       float:right;
       width: 60%;
    }

    .mainContent section:first-child {
        padding-top: 1em;
    }

    .cleverorcfirstrow {
        text-align: left;
    }

    .cleverorcfirstrow p {
        margin: 0;
    }

    .toolList {
        width: 100%;
        text-align: left;
    }

    .phoenixImg {
        padding: 0;
        width: 100%;
    }
    .creditSection {
        width: 80%;
    }
    .creditSection .artists {
        text-align: left;
        width: 100%;
        margin: 0 auto;
    }
  }



.cleverorcimage img  {
    width: 100%;
    height: auto;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    -webkit-filter: FlipH;
            filter: FlipH;
    -ms-filter: "FlipH";

}

.icon16 {
    width: 16px;
}
.privacyPolicy iframe {
    vertical-align: middle;
}
